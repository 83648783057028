import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Image from 'gatsby-image';
import { Spring, animated } from 'react-spring';
import { rem, padding, position } from 'polished';
import { Link } from './link';
import { colors, fontSizes } from '../helper/variables';
import { mq } from '../helper/stylehelper';
import { observeIntersection, isInBrowser } from '../helper/utils';

import mouseHint from '../images/mouse-hint.svg';

const Container = animated(styled.div``);

const CardItem = styled(Link)`
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
    color: ${colors.white};
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    text-decoration: none;
`;

const Cover = styled(Image)`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const PostTag = styled.span`
    ${padding(rem(5), rem(10))};
    display: inline-block;
    margin-left: ${rem(16)};
    margin-bottom: ${rem(16)};
    border-radius: 20px;
    background: ${props => (props.isYoutube ? '#FF0000' : colors.blueDark)};
    font-size: ${rem(12)};
    text-align: center;
    ${mq.smallOnly`
        font-size: ${fontSizes.xxs};
        margin-bottom: ${rem(5)};
        margin-left: ${rem(5)};
    `};
`;

const backgroundVariants = {
    light: 'rgba(255, 255, 255, 0.8)',
    dark: 'rgba(14, 90, 153, 0.8)',
};

const colorVariants = {
    light: colors.blueDark,
    dark: colors.white,
};

const Content = styled.div`
    color: ${props => colorVariants[props.variant]};
    padding: 1em 0.5em;
    font-size: ${fontSizes.xs};
    position: relative;
    background-color: ${props => backgroundVariants[props.variant]};
    line-height: 1.45em;

    ${mq.medium`
        padding: 1em;
        font-size: ${fontSizes.s};
    `};
    ${mq.smallOnly`
        padding: 0.5em 0.7em;
        font-size: ${fontSizes.xxs};
    `};
`;

const Overlay = styled.div`
    ${position('absolute', null, 0, 0, 0)};
    z-index: 2;

    ${props =>
        props.showHoverTeaser
            ? css`
                  &::after {
                      ${position('absolute', null, null, 0, '50%')};
                      box-shadow: 0 0 ${rem(8)} rgb(0, 0, 0, 0.6);
                      border-top-left-radius: ${rem(20)};
                      border-top-right-radius: ${rem(20)};
                      content: url(${mouseHint});
                      display: block;
                      height: ${rem(55)};
                      opacity: 1;
                      transform: translateX(-50%);
                      width: ${rem(55)};
                  }
              `
            : null};
`;

const CoverNormal = styled.img`
    height: 100%;
    object-fit: cover;
    object-position: top center;
    position: absolute;
    width: 100%;
`;

class Card extends Component {
    static propTypes = {
        image: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                aspectRatio: PropTypes.number.isRequired,
                src: PropTypes.string.isRequired,
                srcSet: PropTypes.string.isRequired,
                sizes: PropTypes.string.isRequired,
            }),
        ]).isRequired,
        tag: PropTypes.node,
        text: PropTypes.node.isRequired,
        to: PropTypes.string,
        variant: PropTypes.oneOf(['light', 'dark']),
        className: PropTypes.string,
        target: PropTypes.string,
        rel: PropTypes.string,
        mode: PropTypes.oneOf(['hover', 'default']),
    };

    static defaultProps = {
        tag: '',
        to: '/',
        variant: 'light',
        className: null,
        target: null,
        rel: null,
        mode: 'default',
    };

    element = React.createRef();

    observer = null;

    state = {
        isInViewport: false,
        isMouseOverCard: false,
    };

    /**
     * Lifecycle Hook bei Mount
     */
    componentDidMount() {
        this.observer = observeIntersection(
            this.element.current,
            entry => {
                if (entry.isIntersecting) {
                    this.setState({ isInViewport: true });
                    this.observer.disconnect();
                    this.observer = null;
                }
            },
            { threshold: [0.3] }
        );
    }

    /**
     * Lifecycle Hook bei Unmount
     */
    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    /**
     * Rendert eine Karte
     */
    render() {
        const { isInViewport, isMouseOverCard } = this.state;
        const { image, tag, text, to, variant, className, target, rel, mode } = this.props;

        let imageNode;
        if (typeof image === 'string') {
            imageNode = <CoverNormal src={image} alt={text} />;
        } else {
            imageNode = <Cover fluid={image} />;
        }

        let cardScale = 0.8;
        let cardOpacity = 0;
        if (isInViewport || !isInBrowser) {
            cardScale = 1;
            cardOpacity = 1;
        }
        if (isMouseOverCard) {
            cardScale = 1.03;
        }

        return (
            <Spring native to={{ scale: cardScale, opacity: cardOpacity }}>
                {({ opacity, scale }) => (
                    <Container
                        style={{ opacity, transform: scale.interpolate(s => `scale(${s})`) }}
                        ref={this.element}
                        onMouseEnter={() => this.setState({ isMouseOverCard: true })}
                        onMouseLeave={() => this.setState({ isMouseOverCard: false })}
                        className={className}
                    >
                        <CardItem to={to} target={target} rel={rel}>
                            {!!image && imageNode}
                            <Overlay
                                mode={mode}
                                showHoverTeaser={mode === 'hover' && !isMouseOverCard}
                            >
                                {!!tag && <PostTag>{tag}</PostTag>}
                                {mode !== 'hover' || isMouseOverCard ? (
                                    <Content variant={variant}>{text}</Content>
                                ) : null}
                            </Overlay>
                        </CardItem>
                    </Container>
                )}
            </Spring>
        );
    }
}

export default Card;
