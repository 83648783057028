import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Facebook } from 'styled-icons/fa-brands/Facebook';
import { Instagram } from 'styled-icons/fa-brands/Instagram';
import blueBg from '../images/connect-with-us-bg.png';
import Container from './container';
import headlineSrc from '../images/connect-with-us.svg';

const SocialComponentsWrapper = styled.div`
    padding: 2em 0 1.5em;
    text-align: center;
`;

const SocialComponents = styled.div`
    align-items: center;
    background: url("${blueBg}") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    height: 163px;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    width: 526px;
`;

const Headline = styled.img`
    height: auto;
    margin-bottom: ${rem(10)};
    max-width: 85%;
`;

const SocialIcons = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${rem(15)};
`;

const FacebookIcon = styled(Facebook)`
    margin-right: ${rem(25)};
`;

const InstagramIcon = styled(Instagram)``;

const SocialMedia = () => (
    <SocialComponentsWrapper>
        <Container>
            <SocialComponents>
                <Headline src={headlineSrc} alt="Verbinde dich mit uns" />
                <SocialIcons>
                    <a
                        href="https://www.facebook.com/DieOriginalLechtaler/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FacebookIcon color="#FFFFFF" size="30" title="Lechtaler auf Facebook" />
                    </a>
                    <a
                        href="https://www.instagram.com/dieoriginallechtaler/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <InstagramIcon color="#FFFFFF" size="30" title="Lechtaler auf Instagram" />
                    </a>
                </SocialIcons>
            </SocialComponents>
        </Container>
    </SocialComponentsWrapper>
);

export default SocialMedia;
