import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fontWeights } from '../helper/variables';
import { gapable } from '../helper/stylehelper';

import angleRight from '../images/angle-right.svg';

/**
 * Links
 * ----------------------------------------------------------------------------
 * Element zur automatischen Verwendung von React-Router-Dom-Links/a-Tags
 *
 * @param {bool} props.block Optional: Darstellung als Block-Element
 * @param {node} props.children Der Inhalt
 * @param {string} props.className Optional: zusätzliche CSS-Klassen
 * @param {bool} props.printable Optional: in Print-Ansicht URL automatisch anfügen
 * @param {array} props.to Das Link-Ziel
 *
 * @example
 *  <Link to="https://www.google.de" target="_blank" rel="noopener noreferrer" printable>Zur Webseite</Link>
 */
export const Link = styled(({ className, children, to, ...props }) => {
    const match = to.match(/(^https?|^mailto:|.*\..{3,4}$)/i);

    // In-Page Links
    if (!match) {
        return (
            <GatsbyLink className={`link ${className}`} to={to}>
                {children}
            </GatsbyLink>
        );
    }

    // Externe Links + Downloads
    return (
        <a className={className} href={to} {...props}>
            {children}
        </a>
    );
})``;

Link.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    to: PropTypes.string.isRequired,
};

Link.defaultProps = {
    className: null,
};

/**
 * Link als Block
 */
export const StyledLink = styled(Link)`
    background-color: ${colors.blueDark};
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.4);
    color: ${colors.white};
    display: inline-block;
    font-weight: ${fontWeights.bold};
    padding: 0.5em 1em 0.5em 2em;
    position: relative;
    text-decoration: none;
    transition: box-shadow 200ms;
    ${gapable('s')};

    &::before {
        content: url(${angleRight});
        display: inline-block;
        left: 1em;
        position: absolute;
    }

    &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
`;
