import React, { Component } from 'react';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Spring, animated, config } from 'react-spring';
import { colors } from '../helper/variables';
import { observeIntersection, isInBrowser } from '../helper/utils';

const StyledSection = animated(styled.section`
    padding: ${({ padding }) => padding || `${rem(40)} ${rem(10)}}`};

    ${({ evenOdd }) =>
        evenOdd
            ? css`
                  &:nth-of-type(2n) {
                      background-color: ${colors.grayLighter};
                  }
                  ,padding: 0 !important;
              `
            : null};
`);

export default class Section extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        evenOdd: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        id: PropTypes.string,
        padding: PropTypes.string,
    };

    static defaultProps = {
        evenOdd: true,
        id: null,
        padding: null,
    };

    element = React.createRef();

    observer = null;

    state = {
        isInViewport: false,
    };

    /**
     * Lifecycle Hook bei Mount
     */
    componentDidMount() {
        this.observer = observeIntersection(this.element.current, entry => {
            if (entry.isIntersecting) {
                this.setState({ isInViewport: true });
                this.observer.disconnect();
                this.observer = null;
            }
        });
    }

    /**
     * Lifecycle Hook bei Unmount
     */
    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    /**
     * Rendert die Section
     */
    render() {
        const { isInViewport } = this.state;
        const { children, padding, id, evenOdd } = this.props;

        let sectionOpacity = 1;
        if (!isInViewport && isInBrowser) {
            sectionOpacity = 0;
        }

        return (
            <Spring
                native
                config={config.slow}
                to={{ translateY: isInViewport ? 0 : 100, opacity: sectionOpacity }}
            >
                {({ opacity, translateY }) => (
                    <StyledSection
                        style={{
                            opacity,
                            transform: translateY.interpolate(y => `translateY(${y}px)`),
                        }}
                        ref={this.element}
                        padding={padding}
                        id={id}
                        evenOdd={evenOdd}
                    >
                        {children}
                    </StyledSection>
                )}
            </Spring>
        );
    }
}
