import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'polished';
import Helmet from 'react-helmet';
import 'typeface-fira-sans-condensed';
import 'typeface-fira-sans';
import Header from './header';
import { fontSizes, fontWeights, colors } from '../helper/variables';
import { mq } from '../helper/stylehelper';
import Footer from './footer';
import SocialMedia from './social-media';
import WoodBg from './wood-bg';
import ConclusionBoxes from './conclusion-boxes';
import Section from './section';

const GlobalStyles = createGlobalStyle`
    ${normalize()}

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    body {
        font-family: 'Fira Sans', sans-serif;
        font-size: ${fontSizes.s};
        font-weight: ${fontWeights.normal};

        ${mq.large`
            font-size: ${fontSizes.m};
        `};
    }

    p {
        line-height: 1.5;
        margin: 0 0 0.25em 0;
    }

    a {
        color: ${colors.blueDark};
        text-decoration: underlined;
    }

    hr {
        margin-bottom: 1em;
    }

    b, strong, h1, h2, h3, h4, h5, h6 {
        font-weight: ${fontWeights.bold};
        margin: 0 0 0.15em 0;
        line-height: 1.35;
    }

    .cc-window {
        box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.4);
    }
`;

const Layout = ({ children, title, description, keyvisual, woodBg, conclusionBoxes }) => (
    <>
        <Helmet
            title={title}
            meta={[{ name: 'description', content: description }]}
            htmlAttributes={{
                lang: 'de',
            }}
        />
        <GlobalStyles />
        <>
            {keyvisual}
            {keyvisual === null && <Header />}
            {woodBg ? (
                <WoodBg id="content">
                    {children}
                    <SocialMedia />
                </WoodBg>
            ) : (
                <div id="content">
                    {children}
                    <Section>
                        <ConclusionBoxes boxesToShow={conclusionBoxes} />
                        <SocialMedia />
                    </Section>
                </div>
            )}
            <Footer />
            <script type="application/ld+json">
                {`
                    {
                        "@context": "http://schema.org",
                        "@type": "Organization",
                        "name": "Die Original Lechtaler",
                        "description": "Die zur Mühlen Gruppe ist eines der führenden Unternehmen der Fleisch- und Wurstbranche in Europa. Zu ihr gehören unter anderem die beliebten Wurst-Marken Böklunder, Gutfried, hareico, Lutz, Könecke und Weimarer Thüringen.
                        Nun haben wir unseren Markenstamm um ein besonderes Genusserlebnis erweitert –
                            Die Original Lechtaler.",
                        "image": "https://www.datocms-assets.com/7200/1539847214-product-bergwurst.png?fit=crop&h=311&w=315",
                        "logo": "https://www.die-original-lechtaler.de/static/logo-white-font-65035d53b0fd3a64e232abab8fe268aa.svg",
                        "url": "https://www.die-original-lechtaler.de",
                        "telephone": "+49 8191/105-0",
                        "sameAs": ["https://www.facebook.com/DieOriginalLechtaler","https://www.instagram.com/dieoriginallechtaler/"],
                        "address": {
                            "@type": "PostalAddress",
                        "streetAddress": "Justus-von-Liebig-Straße 48 ",
                        "addressLocality": "Landsberg am Lech",
                        "postalCode": "86899",
                        "addressCountry": "Germany"
                        }
                    }
                `}
            </script>
        </>
    </>
);

Layout.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keyvisual: PropTypes.node,
    woodBg: PropTypes.bool,
    children: PropTypes.node.isRequired,
    conclusionBoxes: PropTypes.arrayOf(PropTypes.string),
};

Layout.defaultProps = {
    title: 'Die Original Lechtaler',
    description: '',
    keyvisual: null,
    woodBg: false,
    conclusionBoxes: ['recipes', 'wiesn'],
};

export default Layout;
