/* eslint-disable import/prefer-default-export */

// Utilities

/**
 * Loggt in die Browser-Konsole, wenn sie verfügbar ist
 * Logging wird in "production" nicht ausgeführt
 * @param  {...any} args Argumente die geloggt werden sollen
 */
export function log(...args) {
    if (
        typeof window !== 'undefined' &&
        window.console &&
        window.console.log &&
        typeof window.console.log === 'function' &&
        process.env.NODE_ENV === 'development'
    ) {
        window.console.log(...args);
    }
}

/**
 * Cheapo Test, ob wir uns im Browser befinden und JS aktiviert ist
 */
export const isInBrowser = typeof window !== 'undefined';

/**
 * Erzeugt einen MutationObserver für ein bestimmtest Element
 * @param {HTMLElement} element Element, dass überwacht werden soll
 * @param {Function} callback Funktion die bei Änderungen aufgerufen wird
 * @param {Object} options Optionen des MutationObservers (https://developer.mozilla.org/en-US/docs/Web/API/MutationObserverInit)
 * @returns {MutationObserver}
 * @see https://github.com/30-seconds/30-seconds-of-code#observemutations-
 */
export function observeMutations(element, callback, options) {
    const observer = new MutationObserver(mutations => mutations.forEach(m => callback(m)));
    observer.observe(
        element,
        Object.assign(
            {
                childList: false,
                attributes: true,
                characterData: false,
            },
            options
        )
    );
    return observer;
}

/**
 * Erzeugt einen IntersectionObserver für ein bestimmtest Element
 * @param {HTMLElement} element Element, dass überwacht werden soll
 * @param {Function} callback Funktion die bei Änderungen aufgerufen wird
 * @param {Object} options Optionen des IntersectionObservers (https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver)
 * @returns {IntersectionObserver}
 */
export function observeIntersection(element, callback, options) {
    const observer = new IntersectionObserver(
        entries => entries.forEach(entry => callback(entry)),
        options
    );

    observer.observe(element);

    return observer;
}

/**
 * Führt eine Media Query aus und informiert optional einen Callback bei Änderungen.
 * @param  {String} query CSS Style Media Query: (min-width: 992px)
 * @param  {Function} callback (optional) Callback wird bei Änderungen informiert
 * @returns {Boolean|undefined} Wenn kein Callback übergeben wird, wird sofort das Ergebnis zurückgeliefert
 */
export function matchMedia(query, callback) {
    const res = window.matchMedia(query);

    if (!callback) {
        return res.matches;
    }

    callback(res.matches, res.media);

    return res.addListener(changed => {
        callback(changed.matches, changed.media);
    });
}

/**
 * Gibt einen URL Parameter zurück
 * @see  http://james.padolsey.com/javascript/bujs-1-getparameterbyname/#comment-32167
 * @return {String}
 */
const cache = {};
export function getParameterByName(name) {
    if (typeof window === 'undefined') {
        return false;
    }

    const sanitizedName = String(name).replace(/[.*+?|()[\]{}\\]/g, '\\$&');

    if (sanitizedName in cache) {
        return cache[sanitizedName];
    }

    const match = new RegExp(`[?&]${sanitizedName}=([^&]*)`).exec(window.location.search);

    if (match) {
        cache[sanitizedName] = decodeURIComponent(match[1].replace(/\+/g, ' '));
    }

    return match && cache[sanitizedName];
}

/**
 * Helper, um ein Bild aus einem GraphQL-Set per Dateiname zu bekommen
 * @param {Array} images Array aus der Query
 * @param {String} path Pfad des gewünschten Bildes
 * @returns {childImageSharp|undefined} Bild oder nix
 */
export function getImageFromNode(images, path) {
    return images.edges.find(({ node }) => node.relativePath === path);
}
