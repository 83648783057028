import { rem } from 'polished';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const maxWidths = {
    small: rem(782),
    inbetweenSmallMedium: rem(1190),
    medium: rem(1290),
    large: '100%',
};

const Container = styled.div`
    display: ${props => props.display};
    margin: 0 auto;
    max-width: ${props => maxWidths[props.maxWidth]};
`;

Container.propTypes = {
    display: PropTypes.string,
    maxWidth: PropTypes.oneOf(['small', 'inbetweenSmallMedium', 'medium', 'large']),
};

Container.defaultProps = {
    display: 'block',
    maxWidth: 'large',
};

export default Container;
