import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';

import { getImageFromNode } from '../helper/utils';

import Container from './container';
import Card from './card';
import { H2 } from './typography';

const elementBreakpoint = 850;

/**
 * Innerer-Wrapper
 * @type {StyledComponent}
 */
const InnerWrapper = styled.div`
    @media (min-width: ${elementBreakpoint}px) {
        display: flex;
    }
`;

const Wrapper = styled(Container)`
    max-width: ${rem(1190)};
    margin: 0.5em auto 1em;
    padding-bottom: 0;
`;

/**
 * Wrapper um die einzelne Card
 * @type {StyledComponent}
 */
const CardWrapper = styled.div`
    width: 100%;

    &:first-child {
        margin-bottom: 1.5em;
    }

    @media (min-width: ${elementBreakpoint}px) {
        height: 0;
        padding-top: ${`${(9 / 16) * 50}%`};
        position: relative;
        &:nth-child(1) {
            margin-right: ${rem(15)};
            margin-bottom: 0;
        }
        &:nth-child(2) {
            margin-left: ${rem(15)};
        }
    }
`;

const StyledCard = styled(Card)`
    max-width: ${rem(450)};
    margin: 0 auto;
    @media (min-width: ${elementBreakpoint}px) {
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        max-width: none;
        width: 100%;
    }
`;

const propTypes = {
    boxesToShow: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    boxesToShow: ['recipies', 'wiesn'],
};

const ConclusionBoxes = ({ boxesToShow }) => (
    <StaticQuery
        query={graphql`
            query CardImageQuery {
                images: allFile(filter: { relativePath: { glob: "content/start/card-*.jpg" } }) {
                    edges {
                        node {
                            relativePath
                            childImageSharp {
                                fluid(maxWidth: 580, quality: 80) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            const { images } = data;

            const boxes = {
                products: (
                    <CardWrapper key="conclusion-recipes">
                        <StyledCard
                            tag="Produkte"
                            text="Ob Bergwurst, Knabber-Stangerl oder Leberkäs – entdecke Die Original Lechtaler-Sortenvielfalt!"
                            to="/produkte/"
                            image={
                                getImageFromNode(
                                    images,
                                    'content/start/card-products-rectangle.jpg'
                                ).node.childImageSharp.fluid
                            }
                            variant="dark"
                        />
                    </CardWrapper>
                ),
                recipes: (
                    <CardWrapper key="conclusion-recipes">
                        <StyledCard
                            tag="Rezepte"
                            text="Einfach genießen: Von köstlichen Vesper-Kreationen, deftigen Leberkäs-Gerichten bis zu herzhaftem Wurstsalat."
                            to="/rezepte/"
                            image={
                                getImageFromNode(images, 'content/start/card-recipes-rectangle.jpg')
                                    .node.childImageSharp.fluid
                            }
                            variant="dark"
                        />
                    </CardWrapper>
                ),
                aboutus: (
                    <CardWrapper key="conclusion-aboutus">
                        <StyledCard
                            tag="Über uns"
                            text="Alles Wissenswerte rund um Die Original Lechtaler: Von der Herkunft bis zur Qualitätssicherung."
                            to="/ueber-uns/"
                            image={
                                getImageFromNode(images, 'content/start/card-aboutus.jpg').node
                                    .childImageSharp.fluid
                            }
                            variant="dark"
                        />
                    </CardWrapper>
                ),
                wheretobuy: (
                    <CardWrapper key="conclusion-wheretobuy">
                        <StyledCard
                            tag="Wo kaufen?"
                            text="Finde heraus, wo es Die Original Lechtaler Sortenvielfalt in deiner Nähe zu kaufen gibt."
                            to="/wo-kaufen/"
                            image={
                                getImageFromNode(images, 'content/start/card-where-to-buy.jpg').node
                                    .childImageSharp.fluid
                            }
                            variant="dark"
                        />
                    </CardWrapper>
                ),
            };

            return (
                <>
                    <Wrapper>
                        <H2 textCentered gap="l">
                            Die Original Lechtaler entdecken
                        </H2>
                        <InnerWrapper>
                            {Object.keys(boxes).map(key => {
                                if (boxesToShow.includes(key)) {
                                    return boxes[key];
                                }

                                return null;
                            })}
                        </InnerWrapper>
                    </Wrapper>
                </>
            );
        }}
    />
);

ConclusionBoxes.propTypes = propTypes;
ConclusionBoxes.defaultProps = defaultProps;

export default ConclusionBoxes;
