import { rem, size } from 'polished';

/**
 * Farben
 */
export const colors = {
    blueDark: '#0E5A99',
    blueLight: '#006fba',
    grayLight: '#D6D6D6',
    grayLighter: '#F3F3F3',
    grayDark: '#333333',
    white: '#FFFFFF',
    black: '#000000',
    red: '#d22321',

    // state colors
    success: '#0E5A99',
    warning: '#ffae00',
    alert: '#cc4b37',
};

/**
 * Abstände
 * @type {Object}
 */
export const gaps = {
    none: 0,
    s: rem(4),
    m: rem(8),
    l: rem(16),
    xl: rem(24),
    xxl: rem(32),
    xxxl: rem(48),
    xxxxl: rem(60),
};

/**
 * Schriftgrößen
 */
export const fontSizes = {
    xxs: rem(12),
    xs: rem(14),
    s: rem(16),
    m: rem(18),
    l: rem(22),
    xl: rem(30),
    xxl: rem(40),
    xxxl: rem(50),
};

/**
 * Schriftdicken
 */
export const fontWeights = {
    default: 400,
    bold: 600,
};

/**
 * Breakpoints
 */
export const breakpoints = {
    xsmall: rem(0),
    small: rem(450),
    medium: rem(640),
    mediumDown: rem(639),
    large: rem(1024),
    largeDown: rem(1023),
    xlarge: rem(1200),
    xlargeDown: rem(1199),
    xxlarge: rem(1440),
    xxlargeDown: rem(1439),
    xxxlarge: rem(1680),
    xxxlargeDown: rem(1679),
};

/**
 * Aspect Ratios
 */
export const aspectRatioLandscape = '160/95'; // 16:9,5 -> 1,684210526315789
export const aspectRatioPortrait = '95/160'; // 9,5:16 -> 0,59375

/**
 * Größe des Hamburger Buttons
 */
export const hamburgerSize = size(rem(40), rem(56));
