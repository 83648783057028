import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { padding, rem, position } from 'polished';
import { Link } from 'gatsby';
import Modal from 'react-modal';
import { Spring, animated } from 'react-spring';
import { colors, hamburgerSize } from '../helper/variables';
import logoSrc from '../images/logo-white-font.svg';
import flagSrc from '../images/header-flag.png';
import HamburgerMenu from './hamburger-menu';
import Nav from './nav';
import WoodBg from './wood-bg';
import { mq } from '../helper/stylehelper';

Modal.setAppElement('#___gatsby');

const Flag = styled.img`
    ${position('absolute')};
    display: none;
    object-fit: cover;
    object-position: left top;
    height: auto;
    max-height: 100%;
    width: 100%;

    ${mq.medium`
        display: block;
    `};

    ${props =>
        props.isHomepage &&
        css`
            height: ${rem(90)};

            ${mq.large`
                height: ${rem(300)};
            `};
        `};

    ${props =>
        props.isHomepage &&
        props.stickFlag &&
        css`
            ${position('fixed', 0, 0, null)};
        `};
`;

const Container = styled.div`
    margin: 0 auto;
    max-width: ${rem(960)};
    position: relative;
    height: ${rem(90)};

    ${mq.large`
        height: ${rem(300)};
    `};
`;

const HeaderInner = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    ${padding(0, rem(20))};

    &::before {
        ${hamburgerSize};
        content: '';
        display: block;
    }

    ${mq.large`
        justify-content: center;
        height: ${rem(228)};

        &::before {
            display: none;
        }
    `};
`;

const Logo = styled.img`
    width: ${rem(120)};
    transition: left 0.3s;

    ${mq.large`
        width: ${rem(270)};
    `};
`;

const ModalLogo = styled.img`
    width: ${rem(150)};
`;

const ModalWrapper = styled.div`
    background: ${colors.blueDark};
    height: 100%;
    width: 100%;
`;

const SpringModal = ({ children, isOpen, ...props }) => (
    <Modal
        isOpen={isOpen}
        style={{
            overlay: {
                ...position('fixed', 0),
                backgroundColor: 'transparent',
                zIndex: '1',
            },
            content: {
                ...position('absolute', 0),
                padding: '0',
                borderRadius: '0',
                border: '0',
                background: 'none',
            },
        }}
        {...props}
    >
        <Spring
            native
            from={{
                left: -100,
                opacity: 0,
            }}
            to={{
                left: isOpen ? 0 : -100,
                opacity: isOpen ? 1 : 0,
            }}
        >
            {({ left, opacity }) => (
                <animated.div
                    style={{
                        height: '100%',
                        opacity,
                        transform: left.interpolate(l => `translateX(${l}%)`),
                    }}
                >
                    <ModalWrapper>{children}</ModalWrapper>
                </animated.div>
            )}
        </Spring>
    </Modal>
);

const ModalInner = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow: auto;
`;

class Header extends Component {
    static propTypes = {
        isHomepage: PropTypes.bool,
    };

    static defaultProps = {
        isHomepage: false,
    };

    state = {
        isModalOpen: false,
        stickFlag: false,
    };

    headerElement = React.createRef();

    /**
     * Lifecycle Hook bei Mount
     */
    componentDidMount() {
        const { isHomepage } = this.props;

        if (isHomepage) {
            this.handleScroll();
            window.addEventListener('scroll', this.handleScroll);
        }
    }

    /**
     * Lifecycle Hook bei Unmount
     */
    componentWillUnmount() {
        const { isHomepage } = this.props;

        if (isHomepage) {
            window.removeEventListener('scroll', this.handleScroll);
        }
    }

    handleScroll = () => {
        if (!this.headerElement.current) {
            return;
        }

        const scrollPos = this.headerElement.current.getBoundingClientRect().top;

        this.setState({
            stickFlag: scrollPos > 0,
        });
    };

    /**
     * Öffnet die Navigation im Overlay
     */
    openModal = () => {
        this.setState({
            isModalOpen: true,
        });
    };

    /**
     * Schließt die Navigation im Overlay
     */
    closeModal = () => {
        this.setState({
            isModalOpen: false,
        });
    };

    /**
     * Öffnet oder schließt die Navigation
     */
    toggleModal = () => {
        this.setState(prevState => ({
            isModalOpen: !prevState.isModalOpen,
        }));
    };

    /**
     * Rendert den Header
     */
    render() {
        const { isModalOpen, stickFlag } = this.state;
        const { isHomepage } = this.props;

        return (
            <>
                <WoodBg as="header" ref={this.headerElement}>
                    <SpringModal
                        isOpen={isModalOpen}
                        onRequestClose={this.closeModal}
                        contentLabel="Navigation"
                        closeTimeoutMS={400}
                    >
                        <ModalInner>
                            <Link to="/">
                                <ModalLogo src={logoSrc} alt="Logo von Lechtaler" />
                            </Link>
                            <Nav isModalOpen />
                        </ModalInner>
                    </SpringModal>

                    <Flag
                        src={flagSrc}
                        alt="Karriertes Tischtuch"
                        aria-hidden="true"
                        isHomepage={isHomepage}
                        stickFlag={stickFlag}
                    />

                    <Container>
                        <HeaderInner isModalOpen={isModalOpen}>
                            <Link to="/">
                                <Logo src={logoSrc} alt="Logo von Lechtaler" />
                            </Link>
                        </HeaderInner>
                        <Nav isModalOpen={false} />
                    </Container>
                </WoodBg>
                <HamburgerMenu toggleModal={this.toggleModal} isModalOpen={isModalOpen} />
            </>
        );
    }
}

export default Header;
