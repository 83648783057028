import React from 'react';
import styled, { css } from 'styled-components';
import { padding, rem } from 'polished';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { fontSizes, fontWeights, colors } from '../helper/variables';
import { mq } from '../helper/stylehelper';

const NavWrapper = styled.nav`
    display: ${props => (props.isModalOpen ? 'block' : 'none')};
    z-index: 1;

    ${mq.large`
        display: block;
    `};

    ${({ isModalOpen }) =>
        isModalOpen &&
        css`
            margin: ${rem(40)} auto 0;
            text-align: center;
            width: 100%;
        `};
`;

const NavList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: ${props => (!props.isModalOpen ? 'row' : 'column')};
    padding-left: 0;
`;

const NavItem = styled.li`
    transition: opacity 0.2s, transform 0.2s;
    font-size: ${fontSizes.m};
    list-style-type: none;

    ${({ isModalOpen }) =>
        isModalOpen &&
        css`
            padding: 10px 0;
            text-align: center;
        `};
`;

const LinkWrapper = ({ isModalOpen, ...props }) => <Link {...props} />;

const activeClassName = 'navlink--active';

const NavLink = styled(LinkWrapper).attrs({
    activeClassName,
})`
    ${padding(rem(7), rem(14))};
    color: ${colors.white};
    font-size: ${fontSizes.l};
    font-weight: ${fontWeights.bold};
    letter-spacing: 0.04em;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    transition: all 0.2s;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.8);

    ${props =>
        !props.isModalOpen &&
        css`
            &:hover,
            &.${activeClassName} {
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
                background: ${colors.blueDark};
                text-shadow: none;
            }
        `};

    ${props =>
        props.isModalOpen &&
        css`
            ${padding(rem(8), rem(20))};
            font-size: ${fontSizes.xl};
            display: inline-block;
            font-weight: ${fontWeights.bold};
            transition: all 0.2s ease-in-out;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

            &:before {
                z-index: -1;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                content: '';
                position: absolute;
                transition: all 0.2s ease-in-out;
                transform: rotate(0deg);
            }

            &:after {
                z-index: -1;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                content: '';
                position: absolute;
                transition: all 0.2s ease-in-out;
            }

            &:hover,
            &.${activeClassName} {
                background: ${colors.blueLight};
                &:before {
                    transform: rotate(-1deg);
                    background-color: ${colors.white};
                }
                &:after {
                    background-color: rgba(249, 249, 249, 0.24);
                }
            }
        `};
`;

const navItemsContent = [
    {
        url: '/produkte/',
        title: 'Produkte',
    },
    {
        url: '/ueber-uns/',
        title: 'Über uns',
    },
    {
        url: '/rezepte/',
        title: 'Rezepte',
    },
    {
        url: '/wo-kaufen/',
        title: 'Wo kaufen?',
    },
];

const Nav = ({ isModalOpen }) => (
    <NavWrapper isModalOpen={isModalOpen}>
        <NavList isModalOpen={isModalOpen}>
            {navItemsContent.map(item => (
                <NavItem key={item.url} isModalOpen={isModalOpen}>
                    <NavLink isModalOpen={isModalOpen} to={item.url}>
                        {item.title}
                    </NavLink>
                </NavItem>
            ))}
        </NavList>
    </NavWrapper>
);

Nav.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
};

export default Nav;
