import styled from 'styled-components';
import wood from '../images/brown-bg.jpg';

const WoodBg = styled.div`
    background: url(${wood});
    position: relative;
    width: 100%;
`;

export default WoodBg;
