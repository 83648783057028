import React from 'react';
import styled from 'styled-components';
import { padding, rem } from 'polished';
import { Link } from 'gatsby';
import Container from './container';
import { fontSizes, colors } from '../helper/variables';
import { mq } from '../helper/stylehelper';

const FooterWrapper = styled.footer`
    background: ${colors.blueDark};
`;

const Content = styled.div`
    ${padding(rem(15), rem(15))};
    display: flex;
    flex-direction: column;
    text-align: center;
    ${mq.medium`
        text-align: none;
        justify-content: space-between;
        flex-direction: row;
    `};
`;

const Copyright = styled.p`
    color: ${colors.white};
    font-size: ${fontSizes.xs};
    margin: 0;
    padding-bottom: ${rem(10)};

    ${mq.medium`
        font-size: ${fontSizes.s};
        padding-bottom: 0;

    `};
`;

const Nav = styled.nav`
    margin: 0;
    padding: 0;
    text-align: center;
`;

const NavLink = styled(Link)`
    ${padding(rem(0), rem(10))};
    color: ${colors.white};
    text-decoration: none;
    border-right: 2px solid ${colors.white};
    font-size: ${fontSizes.xs};

    ${mq.medium`
        font-size: ${fontSizes.s};
    `};

    &:last-child {
        border-right: none;
        padding-right: 0;
    }
`;

const Footer = () => (
    <FooterWrapper>
        <Container maxWidth="medium" padding="0">
            <Content>
                <Copyright>© 2019 zur Mühlen Gruppe. Alle Rechte vorbehalten.</Copyright>
                <Nav>
                    <NavLink to="/impressum/">Impressum</NavLink>
                    <NavLink to="/datenschutz/">Datenschutz</NavLink>
                </Nav>
            </Content>
        </Container>
    </FooterWrapper>
);

export default Footer;
