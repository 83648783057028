import styled, { css } from 'styled-components';
import { fontSizes, colors } from '../helper/variables';
import { mq, gapable } from '../helper/stylehelper';

const headlineStyles = css`
    color: ${({ whiteColor }) => (whiteColor ? colors.white : colors.blueDark)};
    text-align: ${({ textCentered }) => (textCentered ? 'center' : 'left')};
    ${gapable('s')};
`;

export const H1 = styled.h1`
    ${headlineStyles};

    font-size: ${fontSizes.l};
    ${mq.medium`
        font-size: ${fontSizes.xl};
    `};
    ${mq.large`
        font-size: ${fontSizes.xxxl};
    `};
`;

export const H2 = styled.h2`
    ${headlineStyles};

    font-size: ${fontSizes.m};
    ${mq.medium`
        font-size: ${fontSizes.l};
    `};
    ${mq.large`
        font-size: ${fontSizes.xl};
    `};
    ${gapable('s')};
`;

export const H3 = styled.h3`
    ${headlineStyles};

    font-size: ${fontSizes.s};
    ${mq.medium`
        font-size: ${fontSizes.m};
    `};
    ${mq.large`
        font-size: ${fontSizes.l};
    `};
`;

export const H4 = styled.h4`
    ${headlineStyles};

    font-size: ${fontSizes.s};
    ${mq.medium`
        font-size: ${fontSizes.m};
    `};
    ${mq.large`
        font-size: ${fontSizes.l};
    `};
`;

export const P = styled.p`
    color: ${props => (props.whiteColor ? colors.white : colors.black)};
    text-align: ${({ textCentered }) => (textCentered ? 'center' : 'left')};
    line-height: 1.5em;
    ${gapable('m')};

    /* IE FIX */
    max-width: 100%;
    /* END IE FIX */
`;

export const ColoredText = styled.span`
    color: ${({ primaryColor }) => (primaryColor ? colors.blueDark : colors.black)};
    font-weight: bold;
`;
