import React from 'react';
import styled, { css } from 'styled-components';
import { hideVisually, position, rem, size } from 'polished';
import PropTypes from 'prop-types';
import { colors, hamburgerSize } from '../helper/variables';
import { mq } from '../helper/stylehelper';

const HamburgerMenuWrapper = styled.button`
    ${hamburgerSize};
    align-items: center;
    background: ${colors.blueDark};
    border: 0;
    border-radius: ${rem(5)};
    cursor: pointer;
    display: block;
    float: right;
    margin-top: ${rem(-65)};
    outline: none;
    padding: 5px;
    position: ${props => (props.isModalOpen ? 'fixed' : 'sticky')};
    right: ${rem(20)};
    -webkit-appearance: none;
    top: ${props => (props.isModalOpen ? '4.5rem' : '1.75rem')};
    z-index: 2;

    ${mq.large`
        display: ${props => (props.isModalOpen ? 'block' : 'none')};
        `};
`;

const HamburgerMenuLines = styled.span`
    ${size(rem(4), rem(40))};
    background: ${colors.white};
    border-radius: 2px;
    display: inline-block;
    position: relative;
    transition: 0.3s;
    transform: translateY(-3px);

    &::before,
    &::after {
        ${size(rem(4), rem(40))};
        ${position('absolute', null, null, null, 0)};
        background: ${colors.white};
        border-radius: ${rem(2)};

        content: '';
        display: inline-block;
        transition: top 0.3s 0.3s ease, transform 0.3s ease;
        transform-origin: 50% 50%;
    }

    &::before {
        top: -10px;
    }

    &::after {
        top: 10px;
    }

    ${props =>
        props.isModalOpen &&
        css`
            background: transparent;

            &::before,
            &::after {
                top: 0;
                transition: top 0.3s ease, transform 0.3s 0.3s ease;
            }

            &::before {
                transform: rotate3d(0, 0, 1, -45deg);
            }
            &::after {
                transform: rotate3d(0, 0, 1, 45deg);
            }
        `};
`;

const Label = styled.span`
    ${hideVisually()};
`;

const HamburgerMenu = ({ toggleModal, isModalOpen }) => (
    <HamburgerMenuWrapper onClick={toggleModal} isModalOpen={isModalOpen}>
        <HamburgerMenuLines isModalOpen={isModalOpen} />
        <Label>Menü</Label>
    </HamburgerMenuWrapper>
);

export default HamburgerMenu;

HamburgerMenu.propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
};
