import { css } from 'styled-components';
import { rem } from 'polished';
import { aspectRatioLandscape, aspectRatioPortrait, breakpoints, gaps } from './variables';

/**
 * Mixin, um wiederverwertbare MediaQueries zu erhalten
 * @param  {Object} mq Beliebige Anzahl von Zahlen
 * @return {String} In MQ gewrapptes CSS
 * @example
 *     font-size: 16px;
 *     ${mq.medium`font-size: 20px`};
 */
export const mq = {
    smallOnly: (...args) => css`
        @media (max-width: ${breakpoints.mediumDown}) {
            ${css(...args)};
        }
    `,
    medium: (...args) => css`
        @media (min-width: ${breakpoints.medium}) {
            ${css(...args)};
        }
    `,
    mediumDown: (...args) => css`
        @media (max-width: ${breakpoints.largeDown}) {
            ${css(...args)};
        }
    `,
    mediumOnly: (...args) => css`
        @media (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.largeDown}) {
            ${css(...args)};
        }
    `,
    large: (...args) => css`
        @media (min-width: ${breakpoints.large}) {
            ${css(...args)};
        }
    `,
    largeDown: (...args) => css`
        @media (max-width: ${breakpoints.xlargeDown}) {
            ${css(...args)};
        }
    `,
    largeOnly: (...args) => css`
        @media (min-width: ${breakpoints.large}) and (max-width: ${breakpoints.xlargeDown}) {
            ${css(...args)};
        }
    `,
    xlarge: (...args) => css`
        @media (min-width: ${breakpoints.xlarge}) {
            ${css(...args)};
        }
    `,
    xlargeDown: (...args) => css`
        @media (max-width: ${breakpoints.xxlargeDown}) {
            ${css(...args)};
        }
    `,
    xlargeOnly: (...args) => css`
        @media (min-width: ${breakpoints.xlarge}) and (max-width: ${breakpoints.xxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxlarge: (...args) => css`
        @media (min-width: ${breakpoints.xxlarge}) {
            ${css(...args)};
        }
    `,
    xxlargeDown: (...args) => css`
        @media (max-width: ${breakpoints.xxxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxlargeOnly: (...args) => css`
        @media (min-width: ${breakpoints.xxlarge}) and (max-width: ${breakpoints.xxxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxxlarge: (...args) => css`
        @media (min-width: ${breakpoints.xxxlarge}) {
            ${css(...args)};
        }
    `,
};

/**
 * Mixin um zwischen zwei Breakpoints Pixelwerte fließend in Abhängigkeit des Viewports zu skalieren
 * @param {String} cssProp CSS Propterty {String}
 * @param {String} minxPxValue minimaler Pixelwert
 * @param {String} maxPxValue maximaler Pixelwert
 * @param {String} minViewport minimaler Viewport, untere Grenze
 * @param {String} maxViewport maximaler Viewport, obere Grenze
 * @param {boolean} usePixel Pixel statt REM
 * @param {function} mqAddon Zustätzliche Media-Queries als Funktion, die die hiesigen MediaQueries
 *                           an passender Stelle einsetzt
 * @return {String}
 * @see https://fvsch.com/css-locks/
 * @example
 * ${vwHelper('font-size', 16, 20, 320, 1920)};
 */
export const vwMinMax = (
    cssProp,
    minPxValue,
    maxPxValue,
    minViewport = breakpoints.small,
    maxViewport = breakpoints.xxxlarge,
    usePixel,
    mqAddon = null
) => {
    const viewportIncrease = maxViewport - minViewport;

    const minMax = (min, max) => {
        const increase = max - min;

        // return `calc(${min}px + (${max} - ${min}) * ((100vw - ${minViewport}px) / (${maxViewport} - ${minViewport})))`;

        return `
            calc(${(increase / viewportIncrease) * 100}vw + ${min -
            (increase / viewportIncrease) * minViewport}px)
        `;
    };

    let maxValue = usePixel ? `${maxPxValue}px` : rem(maxPxValue);
    let minValue = usePixel ? `${minPxValue}px` : rem(minPxValue);
    let minMaxValue = minMax(minPxValue, maxPxValue);

    // Wenn es multiple Werte sind, ggf. für padding etc.
    if (Array.isArray(minPxValue) && Array.isArray(maxPxValue)) {
        maxValue = usePixel
            ? maxPxValue.map(value => `${value}px`).join(' ')
            : maxPxValue.map(value => rem(value)).join(' ');

        minValue = usePixel
            ? minPxValue.map(value => `${value}px`).join(' ')
            : minPxValue.map(value => rem(value)).join(' ');

        minMaxValue = minPxValue.map((value, index) => minMax(value, maxPxValue[index])).join(' ');
    }

    return `
        ${cssProp}: ${minValue};

        @media ${
            mqAddon
                ? mqAddon(`(min-width: ${rem(minViewport)})`)
                : `(min-width: ${rem(minViewport)})`
        } {
            ${cssProp}: ${minMaxValue};
        }

        @media ${
            mqAddon
                ? mqAddon(`(min-width: ${rem(maxViewport)})`)
                : `(min-width: ${rem(maxViewport)})`
        }  {
            ${cssProp}: ${maxValue};
        }
    `;
};

/**
 * Helper Funktion, die den rem Helper nutzt, wenn eine Zahl übergeben wird
 * @param {Mixed|Number} value
 * @returns {Mixed}
 *
 * @example
 *  remIfNumber(16) => '1rem'
 *  remIfNumber('100%') => '100%';
 */
export const remIfNumber = value => (typeof value === 'number' ? rem(value) : value);

// Media Query für einen Bildschirm der ein moderates Seitenverhältnis hat
export const normalAspectRatioMq = merge => `screen and (min-aspect-ratio: ${aspectRatioPortrait}) and (max-aspect-ratio: ${aspectRatioLandscape}) and ${merge},
        screen and (min-height: 768px) and (min-width: 1024px) and ${merge}`;

// Media Query für Querformat
export const landscapeMq = merge =>
    `screen and (min-aspect-ratio: ${aspectRatioLandscape}) and ${merge}`;

// Media Query für Hochformat
export const portraitMq = merge =>
    `screen and (max-aspect-ratio: ${aspectRatioPortrait}) and ${merge}`;

/**
 * Erweitert Elemente um die Möglichkeit, die Abstände nach unten
 * über global einheitliche Werte zu steuern. Auch mit viewportspezifischen
 * Einstellungen möglich
 *
 * @param {string} defaultGap Optional: der Standard-Abstand
 *
 * @example ${gapable('l')};
 * @example ${gapable({small: 'xl', medium: 'l', ...}};
 */
export const gapable = (defaultGap = 'none') => ({ gap }) => {
    if ((gap && typeof gap === 'object') || (typeof defaultGap === 'object' && !gap)) {
        const gapObject = gap || defaultGap;
        let mqedGaps = '';
        Object.keys(gapObject).forEach(key => {
            const mqFunction = mq[key];

            if (key === 'small') {
                mqedGaps = css`
                    margin-bottom: ${gaps[gapObject[key]]};
                `;
            } else if (mqFunction && gapObject[key]) {
                const query = css`
                    ${mqFunction`margin-bottom: ${gaps[gapObject[key]]}`};
                `;
                mqedGaps = css`${mqedGaps}${query}`;
            }
        });
        return mqedGaps;
    }

    // Gaps ohne mq
    return `margin-bottom: ${gap in gaps ? gaps[gap] : gaps[defaultGap]}`;
};
